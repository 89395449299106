import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import {
  QAListResponse,
  QARequestBody,
  QAResponse,
  AISession,
  AISessionBody,
  AISessionsList,
  QAUpdateBody,
  SuccessResponse,
  AISessionUpdateBody,
  AICellList,
  AIColumnRunBody,
  AIColumnBody,
  AIColumnList,
  AIColumn,
  AIStatus,
  AICell,
  AICellCreateBody,
  AICellUpdateBody,
  AITableList,
  AITable,
  AITableUpdateBody,
  AITableCreateBody,
} from "models/api/response.types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import handleAxiosError from "utils/handleAxiosAlert";

const getAiStatus = (): AxiosPromise<AIStatus> => {
  return axios.get("/api/ai/status");
};

const createAISession = (session: AISessionBody): AxiosPromise<AISession> => {
  return axios.post("/api/ai/session/create", session);
};

const updateAISession = (
  sessionId: number,
  body: AISessionUpdateBody
): AxiosPromise<AISession> => {
  return axios.post(`/api/ai/session/${sessionId}/update`, body);
};

const deleteAISession = (sessionId: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/ai/session/${sessionId}/delete`);
};

const resetAISession = (sessionId: number): AxiosPromise<SuccessResponse> => {
  return axios.post(`/api/ai/session/${sessionId}/reset`);
};

const fetchAISessions = (
  organizationId: number,
  documentId?: number
): AxiosPromise<AISessionsList> => {
  return axios.get(
    `/api/ai/session/list?organization_id=${organizationId}&document_id=${
      documentId || 0
    }`
  );
};

const fetchDocumentQAPairs = (
  sessionId: number
): AxiosPromise<QAListResponse> => {
  return axios.get(`/api/ai/session/${sessionId}/qa/list`);
};

const postQuestion = (
  sessionId: number,
  payload: QARequestBody
): AxiosPromise<QAListResponse> => {
  return axios.post(`/api/ai/session/${sessionId}/qa/create`, payload);
};

const updateQuestion = (
  sessionId: number,
  qaId: number,
  meta: QAUpdateBody
): AxiosPromise<SuccessResponse> => {
  return axios.post(`/api/ai/session/${sessionId}/qa/${qaId}/update`, meta);
};

const deleteQuestion = (
  sessionId: number,
  qaId: number
): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/ai/session/${sessionId}/qa/${qaId}/delete`);
};

const fetchAITables = (organizationId: number): AxiosPromise<AITableList> => {
  return axios.get(`/api/ai/table/list?organization_id=${organizationId}`);
};

const createAITable = (table: AITableCreateBody): AxiosPromise<AITable> => {
  return axios.post(`/api/ai/table/create`, table);
};

const updateAITable = (
  tableId: number,
  body: AITableUpdateBody
): AxiosPromise<AITable> => {
  return axios.post(`/api/ai/table/${tableId}/update`, body);
};

const deleteAITable = (tableId: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/ai/table/${tableId}/delete`);
};

const fetchTableColumns = (tableId: number): AxiosPromise<AIColumnList> => {
  return axios.get(`/api/ai/table/${tableId}/column/list`);
};

const createTableColumn = (
  tableId: number,
  body: AIColumnBody
): AxiosPromise<AIColumn> => {
  return axios.post(`/api/ai/table/${tableId}/column/create`, body);
};

const updateTableColumn = (
  tableId: number,
  columnId: number,
  body: AIColumnBody
): AxiosPromise<AIColumn> => {
  return axios.post(`/api/ai/table/${tableId}/column/${columnId}/update`, body);
};

const deleteTableColumn = (
  tableId: number,
  columnId: number
): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/ai/table/${tableId}/column/${columnId}/delete`);
};

const createTableCell = (
  tableId: number,
  body: AICellCreateBody
): AxiosPromise<AICell> => {
  return axios.post(`/api/ai/table/${tableId}/cell/create`, body);
};

const updateTableCell = (
  tableId: number,
  ai_cell_id: number,
  body: AICellUpdateBody
): AxiosPromise<AICell> => {
  return axios.post(`/api/ai/table/${tableId}/cell/${ai_cell_id}/update`, body);
};

//  Run selected cells in Template Library cells are in [(document_id,column_id),(document_id,column_id),...] format
const runTableCells = (
  tableId: number,
  body: AIColumnRunBody
): AxiosPromise<AICellList> => {
  return axios.post(`/api/ai/table/${tableId}/run`, body);
};

const fetchTableCells = (
  tableId: number,
  document_ids: number[]
): AxiosPromise<AICellList> => {
  return axios.post(`/api/ai/table/${tableId}/documents`, { document_ids });
};

const aiService = {
  getAiStatus,
  createAISession,
  updateAISession,
  deleteAISession,
  fetchAISessions,
  resetAISession,
  postQuestion,
  fetchDocumentQAPairs,
  deleteQuestion,
  updateQuestion,
  createAITable,
  fetchAITables,
  updateAITable,
  deleteAITable,
  fetchTableColumns,
  createTableColumn,
  updateTableColumn,
  deleteTableColumn,
  createTableCell,
  updateTableCell,
  runTableCells,
  fetchTableCells,
};

export default aiService;

// Used to add a cancel property to Promise to appease Typescript
// See https://github.com/tannerlinsley/react-query/issues/1265
interface ExtendedPromise<T> extends Promise<T> {
  cancel?: () => void;
}

export const useTableColumns = (tableId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryKey = [`table/${tableId}/columns`];
  const {
    data: columns,
    isLoading,
    isFetching,
  } = useQuery<unknown, unknown, AIColumnList, any>(
    queryKey,
    (): ExtendedPromise<AIColumnList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiService.fetchTableColumns(tableId);
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AIColumnList }) => responseData
      );
    },
    {
      enabled: !!tableId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedColumnById: (
    id: number
  ) => [item: Readonly<AIColumn> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (columns && columns.length > 0) {
          const idx = columns.findIndex((column) => column.id === id);
          if (idx > -1) {
            return [columns[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [columns]
    );

  const removeCachedColumn = (id: number): AIColumn | undefined => {
    const [currentColumn, index] = getCachedColumnById(id);
    if (columns && currentColumn) {
      const newColumns = [...columns];
      newColumns.splice(index, 1);
      queryClient.setQueryData<AIColumnList>(queryKey, newColumns);
      return currentColumn;
    }
    return undefined;
  };

  const upsertCachedColumn = (newColumn: AIColumn): AIColumn | undefined => {
    const [currentQaMessage, index] = getCachedColumnById(newColumn.id);
    if (columns) {
      const newColumns = [...columns];
      if (index > -1) {
        newColumns.splice(index, 1, newColumn);
      } else {
        newColumns.push(newColumn);
      }
      queryClient.setQueryData<AIColumnList>(queryKey, newColumns);
    } else {
      queryClient.setQueryData<AIColumnList>(queryKey, [newColumn]);
    }
    return currentQaMessage;
  };

  const deleteColumnMutation = useMutation(
    (id: number) => aiService.deleteTableColumn(tableId, id),
    {
      retry: false,
      onMutate: async (id: number) => {
        await queryClient.cancelQueries(queryKey);
        const removedColumn = removeCachedColumn(id);
        return { removedColumn };
      },
      onSettled: (response, error, variables, context) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          if (context?.removedColumn) {
            upsertCachedColumn(context.removedColumn);
            // trigger refetch documents in case rollback caused inconsistency
            queryClient.invalidateQueries(queryKey);
          }
        }
      },
    }
  );

  const updateColumnMutation = useMutation(
    ({ id, body }: Readonly<{ id: number; body: AIColumnBody }>) =>
      aiService.updateTableColumn(tableId, id, body),
    {
      retry: false,
      onMutate: async ({
        id,
        body,
      }: Readonly<{ id: number; body: AIColumnBody }>) => {
        await queryClient.cancelQueries(queryKey);
        const [currentColumn] = getCachedColumnById(id);
        if (currentColumn) {
          const newColumn = { ...currentColumn, ...body };
          return {
            previousColumn: upsertCachedColumn(newColumn),
          };
        }
        return { previousColumn: undefined };
      },
      onSettled: (_response, error) => {
        if (error) {
          handleAxiosError(error as AxiosError, dispatch);
          queryClient.invalidateQueries(queryKey);
        }
      },
    }
  );

  return {
    columns,
    columnsQueryKey: queryKey,
    columnsIsLoading: isLoading,
    columnsIsFetching: isFetching,
    getCachedColumnById,
    upsertCachedColumn,
    updateColumnMutation,
    removeCachedColumn,
    deleteColumnMutation,
  };
};

export const useTableCells = (tableId: number, documentIds: number[]) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryKey = [`table/${tableId}/${JSON.stringify(documentIds)}/cells`];
  const {
    data: cells,
    isLoading,
    isFetching,
  } = useQuery<unknown, unknown, AICellList, any>(
    queryKey,
    (): ExtendedPromise<AICellList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiService.fetchTableCells(tableId, documentIds);
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AICellList }) => responseData
      );
    },
    {
      enabled: !!tableId && !!documentIds.length,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedCellById: (
    id: number
  ) => [item: Readonly<AICell> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (cells && cells.length > 0) {
          const idx = cells.findIndex((cell) => cell.id === id);
          if (idx > -1) {
            return [cells[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [cells]
    );

  const removeCachedCell = (id: number): AICell | undefined => {
    const [currentCell, index] = getCachedCellById(id);
    if (cells && currentCell) {
      const newCells = [...cells];
      newCells.splice(index, 1);
      queryClient.setQueryData<AICellList>(queryKey, newCells);
      return currentCell;
    }
    return undefined;
  };

  const upsertCachedCell = (newCell: AICell): AICell | undefined => {
    const [currentQaMessage, index] = getCachedCellById(newCell.id);
    if (cells) {
      const newCells = [...cells];
      if (index > -1) {
        newCells.splice(index, 1, newCell);
      } else {
        newCells.push(newCell);
      }
      queryClient.setQueryData<AICellList>(queryKey, newCells);
    } else {
      queryClient.setQueryData<AICellList>(queryKey, [newCell]);
    }
    return currentQaMessage;
  };

  return {
    cells,
    cellsQueryKey: queryKey,
    cellsIsLoading: isLoading,
    cellsIsFetching: isFetching,
    getCachedCellById,
    upsertCachedCell,
    removeCachedCell,
  };
};

interface UseAiQAResult {
  qaMessages: QAListResponse | undefined;
  qaMessagesQueryKey: string[];
  qaMessagesIsLoading: boolean;
  qaMessagesIsFetching: boolean;
  getCachedQAMessageById: (
    id: number
  ) => [item: Readonly<QAResponse> | undefined, index: number];
  upsertCachedQAMessage: (newQAMessage: QAResponse) => QAResponse | undefined;
  removeCachedQAMessage: (id: number) => QAResponse | undefined;
  updateQAMessageMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    Readonly<{ id: number; meta: QAUpdateBody }>,
    unknown
  >;
  deleteQAMessageMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    unknown
  >;
}

export const useAiQA = (sessionId: number | undefined): UseAiQAResult => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const qaMessagesQueryKey = [`session/${sessionId}/AiQA`];

  const {
    data: qaMessages,
    isLoading: qaMessagesIsLoading,
    isFetching: qaMessagesIsFetching,
  } = useQuery<unknown, unknown, QAListResponse, any>(
    qaMessagesQueryKey,
    (): ExtendedPromise<QAListResponse> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiService.fetchDocumentQAPairs(sessionId as number);
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: QAListResponse }) => responseData
      );
    },
    {
      enabled: !!sessionId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedQAMessageById: (
    id: number
  ) => [item: Readonly<QAResponse> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (qaMessages && qaMessages.length > 0) {
          const idx = qaMessages.findIndex((message) => message.id === id);
          if (idx > -1) {
            return [qaMessages[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [qaMessages]
    );

  const removeCachedQAMessage = (id: number): QAResponse | undefined => {
    const [currentQaMessage, index] = getCachedQAMessageById(id);
    if (qaMessages && currentQaMessage) {
      const newQaMessages = [...qaMessages];
      newQaMessages.splice(index, 1);
      queryClient.setQueryData<QAListResponse>(
        qaMessagesQueryKey,
        newQaMessages
      );
      return currentQaMessage;
    }
    return undefined;
  };

  const upsertCachedQAMessage = (
    newQaMessage: QAResponse
  ): QAResponse | undefined => {
    const [currentQaMessage, index] = getCachedQAMessageById(newQaMessage.id);
    if (qaMessages) {
      const newQaMessages = [...qaMessages];
      if (index > -1) {
        newQaMessages.splice(index, 1, newQaMessage);
      } else {
        newQaMessages.unshift(newQaMessage);
      }
      queryClient.setQueryData<QAListResponse>(
        qaMessagesQueryKey,
        newQaMessages
      );
    } else {
      queryClient.setQueryData<QAListResponse>(qaMessagesQueryKey, [
        newQaMessage,
      ]);
    }
    return currentQaMessage;
  };

  const deleteQAMessageMutation = useMutation(
    (id: number) => aiService.deleteQuestion(sessionId || -1, id),
    {
      retry: false,
      onMutate: async (id: number) => {
        await queryClient.cancelQueries(qaMessagesQueryKey);
        const removedQAMessage = removeCachedQAMessage(id);
        return { removedQAMessage };
      },
      onSettled: (response, error, variables, context) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          if (context?.removedQAMessage) {
            upsertCachedQAMessage(context.removedQAMessage);
            // trigger refetch documents in case rollback caused inconsistency
            queryClient.invalidateQueries(qaMessagesQueryKey);
          }
        }
      },
    }
  );

  const updateQAMessageMutation = useMutation(
    ({ id, meta }: Readonly<{ id: number; meta: QAUpdateBody }>) =>
      aiService.updateQuestion(sessionId || -1, id, meta),
    {
      retry: false,
      onMutate: async ({
        id,
        meta,
      }: Readonly<{ id: number; meta: QAUpdateBody }>) => {
        await queryClient.cancelQueries(qaMessagesQueryKey);
        const [currentMessage] = getCachedQAMessageById(id);
        if (currentMessage) {
          const newMessage = { ...currentMessage };
          if (meta.user_rating) {
            newMessage.user_rating = meta.user_rating;
          }
          return {
            previousDocument: upsertCachedQAMessage(newMessage),
          };
        }
        return { previousMessage: undefined };
      },
      onSettled: (_response, error) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          queryClient.invalidateQueries(qaMessagesQueryKey);
        }
      },
    }
  );

  return {
    qaMessages,
    qaMessagesQueryKey,
    qaMessagesIsLoading,
    qaMessagesIsFetching,
    getCachedQAMessageById,
    upsertCachedQAMessage,
    updateQAMessageMutation,
    removeCachedQAMessage,
    deleteQAMessageMutation,
  };
};

interface UseAISessions {
  aiSessions: AISessionsList | undefined;
  aiSessiosQueryKey: string[];
  aiSessiosIsLoading: boolean;
  aiSessiosIsFetching: boolean;
  getCachedAISessionById: (
    id: number
  ) => [item: Readonly<AISession> | undefined, index: number];
  createAISessionMutation: UseMutationResult<
    AxiosResponse<AISession>,
    unknown,
    AISessionBody,
    unknown
  >;
  updateAISessionMutation: UseMutationResult<
    AxiosResponse<AISession>,
    unknown,
    {
      sessionId: number;
      body: AISessionUpdateBody;
    },
    unknown
  >;
  deleteAISessionMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    unknown
  >;
}

export const useAISessions = (
  organizationId?: number,
  documentId?: number
): UseAISessions => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const aiSessiosQueryKey = [
    `organization/${organizationId}${
      documentId ? `/document/${documentId}` : ""
    }/aiSessions`,
  ];

  const {
    data: aiSessions,
    isLoading: aiSessiosIsLoading,
    isFetching: aiSessiosIsFetching,
  } = useQuery<unknown, unknown, AISessionsList, any>(
    aiSessiosQueryKey,
    (): ExtendedPromise<AISessionsList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiService.fetchAISessions(
        organizationId as number,
        documentId
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AISessionsList }) => responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedAISessionById: (
    id: number
  ) => [item: Readonly<AISession> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (aiSessions && aiSessions.length > 0) {
          const idx = aiSessions.findIndex((session) => session.id === id);
          if (idx > -1) {
            return [aiSessions[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [aiSessions]
    );

  const upsertCachedAISession = (
    newAiSession: AISession
  ): AISession | undefined => {
    const [currentAISession, index] = getCachedAISessionById(newAiSession.id);
    if (aiSessions) {
      const newAISessions = [...aiSessions];
      if (index > -1) {
        newAISessions.splice(index, 1, newAiSession);
      } else {
        newAISessions.unshift(newAiSession);
      }
      queryClient.setQueryData<AISessionsList>(
        aiSessiosQueryKey,
        newAISessions
      );
    } else {
      queryClient.setQueryData<AISessionsList>(aiSessiosQueryKey, [
        newAiSession,
      ]);
    }
    return currentAISession;
  };

  const removeCachedAiSession = (id: number): AISession | undefined => {
    const [currentAISession, index] = getCachedAISessionById(id);
    if (aiSessions && currentAISession) {
      const newAiSessions = [...aiSessions];
      newAiSessions.splice(index, 1);
      queryClient.setQueryData<AISessionsList>(
        aiSessiosQueryKey,
        newAiSessions
      );
      return currentAISession;
    }
    return undefined;
  };

  const createAISessionMutation = useMutation(
    (variables: AISessionBody) => aiService.createAISession(variables),
    {
      retry: false,
      onSuccess: (response) => {
        if (response?.data) {
          upsertCachedAISession(response.data);
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(aiSessiosQueryKey);
      },
    }
  );

  const updateAISessionMutation = useMutation(
    (variables: { sessionId: number; body: AISessionUpdateBody }) => {
      const { sessionId, body } = variables;
      return aiService.updateAISession(sessionId, body);
    },
    {
      retry: false,
      onMutate: async (variables: {
        sessionId: number;
        body: AISessionUpdateBody;
      }) => {
        const { sessionId, body } = variables;
        const [currentAISession] = getCachedAISessionById(sessionId);
        if (currentAISession && aiSessions) {
          const updatedAISession = {
            ...currentAISession,
            ...(body.meta && {
              meta: body.meta,
            }),
            ...(body.visibility && {
              visibility: body.visibility,
            }),
            ...(body.document_ids && {
              document_ids: body.document_ids,
            }),
          };
          upsertCachedAISession(updatedAISession);
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(aiSessiosQueryKey);
      },
    }
  );

  const deleteAISessionMutation = useMutation(
    (id: number) => aiService.deleteAISession(id),
    {
      retry: false,
      onMutate: async (id: number) => {
        removeCachedAiSession(id);
      },
      onSettled: (_response, error) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          queryClient.invalidateQueries(aiSessiosQueryKey);
        }
      },
    }
  );

  return {
    aiSessions,
    aiSessiosQueryKey,
    aiSessiosIsLoading,
    aiSessiosIsFetching,
    getCachedAISessionById,
    createAISessionMutation,
    updateAISessionMutation,
    deleteAISessionMutation,
  };
};

interface UseAITables {
  aiTables: AITableList | undefined;
  aiTablesQueryKey: string[];
  aiTablesIsLoading: boolean;
  aiTablesIsFetching: boolean;
  getCachedAITableById: (
    id: number
  ) => [item: Readonly<AITable> | undefined, index: number];
  createAITableMutation: UseMutationResult<
    AxiosResponse<AITable>,
    unknown,
    AITableCreateBody,
    unknown
  >;
  updateAITableMutation: UseMutationResult<
    AxiosResponse<AITable>,
    unknown,
    {
      tableId: number;
      body: AITableUpdateBody;
    },
    unknown
  >;
  deleteAITableMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    unknown
  >;
}

export const useAITables = (organizationId?: number): UseAITables => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const aiTablesQueryKey = [`organization/${organizationId}/aiTables`];

  const {
    data: aiTables,
    isLoading: aiTablesIsLoading,
    isFetching: aiTablesIsFetching,
  } = useQuery<unknown, unknown, AITableList, any>(
    aiTablesQueryKey,
    (): ExtendedPromise<AISessionsList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiService.fetchAITables(organizationId as number);
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AITableList }) => responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedAITableById: (
    id: number
  ) => [item: Readonly<AITable> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (aiTables && aiTables.length > 0) {
          const idx = aiTables.findIndex((table) => table.id === id);
          if (idx > -1) {
            return [aiTables[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [aiTables]
    );

  const upsertCachedAITable = (newAiTable: AITable): AITable | undefined => {
    const [currentAITable, index] = getCachedAITableById(newAiTable.id);
    if (aiTables) {
      const newAITables = [...aiTables];
      if (index > -1) {
        newAITables.splice(index, 1, newAiTable);
      } else {
        newAITables.unshift(newAiTable);
      }
      queryClient.setQueryData<AITableList>(aiTablesQueryKey, newAITables);
    } else {
      queryClient.setQueryData<AITableList>(aiTablesQueryKey, [newAiTable]);
    }
    return currentAITable;
  };

  const removeCachedAiTable = (id: number): AITable | undefined => {
    const [currentAITable, index] = getCachedAITableById(id);
    if (aiTables && currentAITable) {
      const newAiTables = [...aiTables];
      newAiTables.splice(index, 1);
      queryClient.setQueryData<AITableList>(aiTablesQueryKey, newAiTables);
      return currentAITable;
    }
    return undefined;
  };

  const createAITableMutation = useMutation(
    (variables: AITableCreateBody) => aiService.createAITable(variables),
    {
      retry: false,
      onSuccess: (response) => {
        if (response?.data) {
          upsertCachedAITable(response.data);
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(aiTablesQueryKey);
      },
    }
  );

  const updateAITableMutation = useMutation(
    (variables: { tableId: number; body: AITableUpdateBody }) => {
      const { tableId, body } = variables;
      return aiService.updateAITable(tableId, body);
    },
    {
      retry: false,
      onMutate: async (variables: {
        tableId: number;
        body: AITableUpdateBody;
      }) => {
        const { tableId, body } = variables;
        const [currentAITable] = getCachedAITableById(tableId);
        if (currentAITable && aiTables) {
          const updatedAITable = {
            ...currentAITable,
            ...(body.meta && {
              meta: body.meta,
            }),
            ...(body.visibility && {
              visibility: body.visibility,
            }),
            ...(body.document_ids && {
              document_ids: body.document_ids,
            }),
          };
          upsertCachedAITable(updatedAITable);
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(aiTablesQueryKey);
      },
    }
  );

  const deleteAITableMutation = useMutation(
    (id: number) => aiService.deleteAITable(id),
    {
      retry: false,
      onMutate: async (id: number) => {
        removeCachedAiTable(id);
      },
      onSettled: (_response, error) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          queryClient.invalidateQueries(aiTablesQueryKey);
        }
      },
    }
  );

  return {
    aiTables,
    aiTablesQueryKey,
    aiTablesIsLoading,
    aiTablesIsFetching,
    getCachedAITableById,
    createAITableMutation,
    updateAITableMutation,
    deleteAITableMutation,
  };
};
